@charset "UTF-8";

@font-face {
  font-family: "hero-icon-font-3";
  src:url("../assets/fonts/hero-icon-font-3.eot");
  src:url("../assets/fonts/hero-icon-font-3.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/hero-icon-font-3.woff") format("woff"),
    url("../assets/fonts/hero-icon-font-3.ttf") format("truetype"),
    url("../assets/fonts/hero-icon-font-3.svg#hero-icon-font-3") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "hero-icon-font-3" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="hl-icon-"]:before,
[class*=" hl-icon-"]:before {
  font-family: "hero-icon-font-3" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hl-icon-arrow:before {
  content: "\61";
}
.hl-icon-bathtub:before {
  content: "\63";
}
.hl-icon-cross-red:before {
  content: "\66";
}
.hl-icon-dishwasher:before {
  content: "\67";
}
.hl-icon-eye-closed:before {
  content: "\69";
}
.hl-icon-facebook:before {
  content: "\6a";
}
.hl-icon-faceid:before {
  content: "\6b";
}
.hl-icon-fingerprint:before {
  content: "\6c";
}
.hl-icon-google:before {
  content: "\6f";
}
.hl-icon-irrigation:before {
  content: "\73";
}
.hl-icon-linkedin:before {
  content: "\74";
}
.hl-icon-tick:before {
  content: "\77";
}
.hl-icon-shower:before {
  content: "\79";
}
.hl-icon-toilet:before {
  content: "\41";
}
.hl-icon-w-machine:before {
  content: "\44";
}
.hl-icon-add:before {
  content: "\46";
}
.hl-icon-plus:before {
  content: "\48";
}
.hl-icon-pets:before {
  content: "\49";
}
.hl-icon-office:before {
  content: "\4a";
}
.hl-icon-more-less:before {
  content: "\4b";
}
.hl-icon-house:before {
  content: "\4d";
}
.hl-icon-minus:before {
  content: "\51";
}
.hl-icon-close:before {
  content: "\52";
}
.hl-icon-home:before {
  content: "\54";
}
.hl-icon-trash:before {
  content: "\55";
}
.hl-icon-stats:before {
  content: "\57";
}
.hl-icon-notifications:before {
  content: "\58";
}
.hl-icon-settings:before {
  content: "\59";
}
.hl-icon-events:before {
  content: "\5a";
}
.hl-icon-help:before {
  content: "\53";
}
.hl-icon-question-mark:before {
  content: "\30";
}
.hl-icon-children:before {
  content: "\50";
}
.hl-icon-adults:before {
  content: "\47";
}
.hl-icon-tap:before {
  content: "\7a";
}
.hl-icon-info:before {
  content: "\33";
}
.hl-icon-edit:before {
  content: "\34";
}
.hl-icon-arrow-d:before {
  content: "\35";
}
.hl-icon-arrow-r:before {
  content: "\36";
}
.hl-icon-arrow-l:before {
  content: "\37";
}
.hl-icon-arrow-u:before {
  content: "\38";
}
.hl-icon-currency:before {
  content: "\39";
}
.hl-icon-phone:before {
  content: "\21";
}
.hl-icon-password:before {
  content: "\22";
}
.hl-icon-delete-account:before {
  content: "\23";
}
.hl-icon-city:before {
  content: "\24";
}
.hl-icon-water-cost:before {
  content: "\25";
}
.hl-icon-plumbers:before {
  content: "\26";
}
.hl-icon-log-out:before {
  content: "\27";
}
.hl-icon-shut-off:before {
  content: "\28";
}
.hl-icon-country:before {
  content: "\29";
}
.hl-icon-name:before {
  content: "\2a";
}
.hl-icon-localization:before {
  content: "\2b";
}
.hl-icon-circle-of-trust:before {
  content: "\2c";
}
.hl-icon-user:before {
  content: "\2d";
}
.hl-icon-postcode:before {
  content: "\2e";
}
.hl-icon-mail:before {
  content: "\2f";
}
.hl-icon-building:before {
  content: "\3a";
}
.hl-icon-exclamation:before {
  content: "\3b";
}
.hl-icon-bridge:before {
  content: "\3c";
}
.hl-icon-sonic:before {
  content: "\3e";
}
.hl-icon-no-internet:before {
  content: "\3f";
}
.hl-icon-local:before {
  content: "\5b";
}
.hl-icon-settings-2:before {
  content: "\5d";
}
.hl-icon-pressure-test:before {
  content: "\5e";
}
.hl-icon-sun:before {
  content: "\5f";
}
.hl-icon-check-up:before {
  content: "\7b";
}
.hl-icon-eye-open:before {
  content: "\7c";
}
.hl-icon-fridge:before {
  content: "\7d";
}
.hl-icon-other:before {
  content: "\7e";
}
.hl-icon-shield:before {
  content: "\5c";
}
.hl-icon-keyboard:before {
  content: "\e000";
}
.hl-icon-camera:before {
  content: "\e001";
}
.hl-icon-scan-qr:before {
  content: "\e002";
}
.hl-icon-box:before {
  content: "\62";
}

.hl-icon {
    font-size: 22px;
    padding: 5px;
    background: white;
    border-radius: 20px;
    color: #20a8d8;
}
