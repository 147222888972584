// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles
@import "~@coreui/coreui/scss/coreui";

@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// If you want to add something do it here
@import "custom";
@import "@angular/cdk/overlay-prebuilt.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

// hero labs icons
@import "hero-icons";

// regular style toast messages
@import '~ngx-toastr/toastr';