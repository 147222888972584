.wrapper {
    width: 100%;
    @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
    @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
    will-change: auto;
    @include transition(padding .15s);
}

.btn-primary {
    --cui-btn-color: #ffffff;
}

.tab-content {
    margin-top: -1px;
    background: #fff;
    border: 1px solid #c8ced3;

    .tab-pane {
        padding: 1rem;
    }
}

.pointer {
    cursor: pointer;
}

input.ng-dirty.ng-invalid {
    border-color: #f86c6b;
    padding-right: calc(1.5em + 0.75rem);
}

.round-badge-score {
    background: green;
    color: white;
    padding: 5px;
    width: 35px;
    height: 35px;
    display: inline-flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    font-size: 16px;

    &.green {
        background: #37b34a;
    }

    &.red {
        background: #ec1c24;
    }
}

.score-summary {
    display: inline-block;

    p {
        display: inline-block;
    }
}

.score-info {
    font-size: 18px;
    position: absolute;
    min-width: 300px;
}

.text-strike-through {
    text-decoration: line-through;
}

.table-hover.table-select {
    cursor: pointer;
}

.full-height {
    height: 100%;
}

.table-fixed {
    table-layout: fixed;
}

.dl-inline {
    dt, dd {
        display: inline-block;
    }
}

.collapse-anchor {
    cursor: pointer;
    color: #8fd0eb !important;
}

a.link {
    color: #20a8d8 !important;
    cursor: pointer;
}


.app-body {
    overflow: visible;
}

:root {
    --navbar-brand-height: #{$navbar-brand-height};
}

.ngx-datatable {

    .datatable-header {
        position: sticky;
        top:110px;
        background: white;
        z-index: 999;
    }

    &.material {
        overflow: visible;
        box-shadow: none;

        .datatable-body .datatable-body-row .datatable-body-cell {

        }

        &.multi-selection,
        &.single-selection {
            .datatable-body-row.active .datatable-row-group,
            .datatable-body-row.active {
                .datatable-body-cell {
                    color: white;
                }
            }
        }
    }
}

.table-wrapper {
    position: relative;
    //@include mq($until: lg) {
    //	.table-box {
    //		overflow: scroll;
    //
    //		ngx-datatable {
    //			min-width: 1000px;
    //		}
    //	}
    //}


    .table-loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
        background: rgba(255, 255, 255, 0.6);

        .spinner {
            position: sticky;
            top: 50%;
        }
    }
}

.selection-enabled .datatable-body-row:hover {
    cursor: pointer;
}

.state-indicator {
    width: 12px;
    height: 12px;
    margin: auto;
    border: 2px solid #F0F0F1;
    border-radius: 100%;
    background: $successGreen;

    &.yellow,
    &.low {
        background: $errorYellow;
    }

    &.orange,
    &.mid {
        background: $errorOrange;
    }

    &.red,
    &.high {
        background: $errorRed;
    }
}

app-header {
    display: flex;
    padding-right: 50px !important;

    button {
        order: 1;
    }

    a {
        position: static !important;
        order: 2;
        margin: 0 auto !important;
    }
}

app-sidebar-nav-items app-sidebar-nav-items {
    padding-left: 15px !important;
}

.btn-toolbar .btn {
    margin-right: 5px;
    margin-bottom: 5px;
}

.btn-toolbar .btn:last-child {
    margin-right: 0;
}

.result-table {
    th, td {
        padding: 5px;
        text-align: left;
        border-collapse: collapse;
    }
}

.modal-xxl {
    max-width: 80vw;
}

.bs-tooltip-bottom {
    position: absolute;
    overflow: visible;
}

.buttons-row {
    white-space: nowrap;
}

.ml-2 {
    margin-left: 5px;
}

.modal-body {
    overflow: auto;
}

app-data-table {
    display: block;
    overflow: auto;
}

datatable-body-cell.datatable-body-cell.sort-active.ng-star-inserted {
    white-space: normal !important;
}

.datatable-body-row:hover,
.datatable-body-row:hover .datatable-row-group {
    background-color: #e9ecef !important;
}

.cdk-overlay-container {
    z-index: 10000;
}

.card-body {
    container-type: inline-size;
}

@mixin flattenDl($labelSize: 30%) {
    $columnsSize: minmax(auto, $labelSize) 1fr;

    display: grid;
    grid-template-columns: $columnsSize;
    grid-template-rows: auto;

    > *:not(dt):not(dd) {
        grid-column: 1/3;

        display: grid;
        grid-template-columns: $columnsSize;
        grid-template-rows: auto;
    }

    dt {
        padding-right: 15px;
    }

    dt,
    dd {
        border-bottom: 1px solid #dcdcdc;
        margin-bottom: 0.25rem;
        padding-bottom: 0.25rem;
    }
}

@container (width > 500px) {

    dl {
        @include flattenDl();
    }
}

@container (width > 300px) {

    dl.small-list {
        @include flattenDl(50%);
    }
}
